<template>
    <div class="app-container">
      <div class="main-body">
        <el-row class="call-title">
          <el-col :span="24">
            <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
            <span class="title">设备管理</span>
          </el-col>
        </el-row>
        <div ref="searchBar" class="form-cls">
          <el-form @heightChange="_getTableHeight()">
            <el-row>
              <el-col :span="6">
                <el-form-item label="序列号:">
                  <el-input
                    v-model.trim="queryParams.seriNo"
                    clearable
                    type="text"
                    size="small"
                    class="input-cls input-width-6"
                    placeholder="请输入设备序列号"
                    @keyup.enter.native="queryList"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="场地类型：" prop="placeType">
                  <el-select
                      v-model="queryParams.placeType"
                      onchange="selectQueryPlaceType"
                      clearable
                  >
                    <el-option label="工地" value="0"></el-option>
                    <el-option label="消纳场" value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工地：" v-if="queryParams.placeType == '0'" prop="projectId">
                  <el-select
                      v-model="queryParams.projectId"
                      :multiple="false"
                      size="small"
                      filterable
                      class="input-width-9"
                      clearable
                      @change="selectQueryRecycle"
                      placeholder="请选择工地"
                  >
                    <el-option
                        v-for="item in projectList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="消纳场：" v-else prop="projectId">
                  <el-select
                      v-model="queryParams.projectId"
                      :multiple="false"
                      filterable
                      size="small"
                      class="input-width-9"
                      clearable
                      @change="selectQueryRecycle"
                      placeholder="请选择消纳场"
                  >
                    <el-option
                        v-for="item in recycleList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item>
                  <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- </search-bar> -->
        </div>
        <div class="main-body">
          <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
            <template #buttons>
              <el-button plain key="set" size="small" type="primary" icon="el-icon-circle-plus" @click="addRow"> 新增设备 </el-button>
            </template>
            <template #tools>
              <vxe-pager
                align="left"
                placement="top"
                :total="total"
                :current-page.sync="queryParams.current"
                border
                :page-sizes="[100, 120, 150]"
                size="small"
                :page-size.sync="queryParams.size"
                :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="queryList('0')"
              />
            </template>
          </vxe-toolbar>
        </div>
        <el-row class="form-cls button-cls">
          <el-col :span="24">
            <vxe-table
              id="dataListTable"
              ref="table"
              v-loading="listLoading"
              class="sortable-column-demo"
              :export-config="{}"
              :data="dataList"
              fit
              show-footer
              :height="_tableHeight"
              element-loading-text="加载中"
              border
              stripe
              column-key
              resizable
              highlight-current-row
              :resizable-config="{ minWidth: 50 }"
              :checkbox-config="{ trigger: 'row', highlight: true }"
              :custom-config="{ storage: true }"
              :scroll-y="{ gt: -1 }"
              show-overflow="tooltip"
              show-footer-overflow="tooltip"
              show-header-overflow="tooltip"
              tooltip-effect="dark"
              size="small"
              style="width: 100%"
            >
              <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
              <vxe-table-column field="seriNo" title="设备序列号" sortable align="center" width="200" show-overflow-tooltip />
              <vxe-table-column field="account" title="设备账号" sortable align="center" width="100" show-overflow-tooltip />
              <vxe-table-column field="passwd" title="设备密码" sortable align="center" width="100" show-overflow-tooltip />
              <vxe-table-column field="localIp" title="局域网ip" sortable align="center" width="100" show-overflow-tooltip />
              <vxe-table-column field="localPort" title="局域网端口号" sortable align="center" width="150" show-overflow-tooltip />
              <vxe-table-column field="playDeviceSeriNo" title="设备播放序列号" sortable align="center" width="150" show-overflow-tooltip />
              <vxe-table-column field="playChannel" title="播放路数" sortable align="center" width="150" show-overflow-tooltip />
              <vxe-table-column field="projectName" title="绑定的工地" sortable align="center" width="150" show-overflow-tooltip />
              <vxe-table-column field="placeType" title="场地类型" sortable align="center" width="140">
                <template slot-scope="scope">
                  <el-tag :type="scope.row.placeType | placeTypeTpFilter" size="small">
                    {{ scope.row.placeType | placeTypeFilter }}
                  </el-tag>
                </template>
              </vxe-table-column>
              <vxe-table-column field="gateName" title="场地出口" sortable align="center" width="150" show-overflow-tooltip />

              <vxe-table-column field="deviceUsage" title="设备用途" sortable align="center" width="140">
                <template slot-scope="scope">
                  <el-tag :type="scope.row.deviceUsage | deviceUsageTypeFilter" size="small">
                    {{ scope.row.deviceUsage | deviceUsageFilter }}
                  </el-tag>
                </template>
              </vxe-table-column>

              <vxe-table-column field="status" title="设备状态" sortable align="center" width="140">
                <template slot-scope="scope">
                  <el-tag :type="scope.row.status | statusTypeFilter" size="small">
                    {{ scope.row.status | statusFilter }}
                  </el-tag>
                </template>
              </vxe-table-column>
              <vxe-table-column field="macAddress" title="mac地址" align="center" width="200" />
              <vxe-table-column field="created" title="录入时间" align="center" width="200" />
              <vxe-table-column field="creater" align="center" title="录入人" sortable width="200" />
              <vxe-table-column align="center" fixed="right" title="操作" width="240">
                <template v-slot="{ row }">
                  <span style="color: deepskyblue; cursor: pointer" icon="fa fa-edit" @click="editEvent(row)">编辑</span>—
                  <span style="color: red; cursor: pointer" v-if="row.status === 'ENABLE'" icon="fa fa-trash-o" @click="disableEvent(row)">下线</span>
                  <span style="color: darkgreen; cursor: pointer" v-if="row.status === 'DISABLE'" icon="fa fa-trash-o" @click="enableEvent(row)"
                    >上线</span>
                  <span v-if="row.projectId != null ">-</span>
                  <span style="color: red; cursor: pointer" v-if="row.projectId != null " icon="fa fa-trash-o" @click="clearBindEvent(row)">解绑</span>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-col>
        </el-row>
      </div>
      <!-- 添加设备 -->
      <el-dialog :visible.sync="dialogVisible" :title="dialogTitle" width="60%" :close-on-click-modal="false">
        <el-form :model="formData" ref="formData" :rules="rules" label-width="150px">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="设备序列号：" prop="seriNo">
                <el-input v-model="formData.seriNo" size="small" class="input-width-8" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备播放序列号：" prop="seriNo">
                <el-input v-model="formData.playDeviceSeriNo" placeholder="默认是设备序列号" size="small" class="input-width-8" />
              </el-form-item>
            </el-col>

          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="设备播放路数：" prop="playChannel">
                <el-input v-model="formData.playChannel" size="small" class="input-width-8" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="场地类型：" prop="placeType">
                <el-select
                    v-model="formData.placeType"
                    onchange="selectPlaceType"
                >
                  <el-option label="工地" value="PROJECT"></el-option>
                  <el-option label="消纳场" value="RECYCLE"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备账号：" prop="account">
                <el-input v-model="formData.account" size="small" class="input-width-8" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备密码：" prop="passwd">
                <el-input v-model="formData.passwd" size="small" class="input-width-8" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="局域网ip：" prop="localIp">
                <el-input v-model="formData.localIp" size="small" placeholder="局域网ip，默认为192.168.0.40" class="input-width-8" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="局域网端口号：" prop="localPort">
                <el-input v-model="formData.localPort" size="small" placeholder="局域网端口，默认为8000" class="input-width-8" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备mac地址：" prop="macAddress">
                <el-input v-model="formData.macAddress" size="small" placeholder="车头相机需要填写mac地址" class="input-width-8" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
                <el-form-item label="设备用途：" prop="deviceUsage">
                  <el-select
                      v-model="formData.deviceUsage"
                      onchange="selectDeviceUsage"
                  >
                    <el-option label="车头" value="CAR_HEAD"></el-option>
                    <el-option label="车斗" value="CAR_BODY"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备别名：" prop="deviceName">
                <el-input v-model="formData.deviceName" size="small" class="input-width-8" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="绑定工地：" v-if="formData.placeType == 'PROJECT'" prop="projectId">
                <el-select
                    v-model="formData.projectName"
                    :multiple="false"
                    size="small"
                    filterable
                    class="input-width-9"
                    @change="selectRecycle"
                    placeholder="请选择工地"
                >
                  <el-option
                      v-for="item in projectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="绑定消纳场：" v-else prop="projectId">
                <el-select
                    v-model="formData.projectName"
                    :multiple="false"
                    filterable
                    size="small"
                    class="input-width-9"
                    @change="selectRecycle"
                    placeholder="请选择消纳场"
                >
                  <el-option
                      v-for="item in recycleList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>

              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="场地出口：" prop="gateName">
                <el-select
                    v-model="formData.gateName"
                    onchange="selectGateName"
                >
                  <el-option label="一号出口" value="一号出口"></el-option>
                  <el-option label="二号出口" value="二号出口"></el-option>
                  <el-option label="三号出口" value="三号出口"></el-option>
                  <el-option label="四号出口" value="四号出口"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- :normalizer="normalizer" -->
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitData">提 交</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  <script>
  import { queryList, deviceEdit, deviceSave, updateStatus, clearBind } from '@/api/device'
  import { queryAll as queryAllTenant } from '@/api/tenant'
  import { listAll as listAllProjectList } from '@/api/project'
  import { getToken } from '@/utils/auth'
  import CalTableHeight from '@/mixins/calTableHeight'
  import Sortable from 'sortablejs'
  import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import {getAllRecyleProjectList} from "@/api/recycle";

  export default {
    components: { Treeselect },
    mixins: [CalTableHeight],
    filters: {
      statusTypeFilter(status) {
        const statusMap = {
          ENABLE: 'success',
          DISABLE: 'danger'
        }
        return statusMap[status]
      },
      statusFilter(status) {
        const statusMap = {
          ENABLE: '在线',
          DISABLE: '下线'
        }
        return statusMap[status]
      },

      placeTypeTpFilter(status) {
        const statusMap = {
          PROJECT: 'success',
          RECYCLE: 'danger'
        }
        return statusMap[status]
      },
      placeTypeFilter(status) {
        const statusMap = {
          PROJECT: '工地',
          RECYCLE: '消纳场'
        }
        return statusMap[status]
    },

      deviceUsageTypeFilter(status) {
        const statusMap = {
          CAR_HEAD: 'success',
          CAR_BODY: 'danger'
        }
        return statusMap[status]
      },
      deviceUsageFilter(status) {
        const statusMap = {
          CAR_HEAD: '车头',
          CAR_BODY: '车斗'
        }
        return statusMap[status]
      }
    },
    data: function () {
      const validatPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'))
        } else {
          const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          if (reg.test(value)) {
            callback()
          } else {
            return callback(new Error('请输入正确的手机号'))
          }
        }
      }

      const validatCreditCode = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('纳税人识别号不能为空'))
        } else {
          const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
          if (reg.test(value)) {
            callback()
          } else {
            return callback(new Error('请输入正确的统一社会信用代码'))
          }
        }
      }
      return {
        total: 0,
        summary: {},
        listLoading: false,
        formData: {
          id: '',
          seriNo: '',
          deviceName: '',
          account: '',
          passwd: '',
          projectId: '',
          projectName: '',
          placeType:'PROJECT',

          gateName:'',
          playDeviceSeriNo: '',
          playChannel: 1,
          localIp: '',
          localPort: '',
          macAddress:'',
          deviceUsage:null,


        },
        rules: {
          seriNo: [{ required: true, message: '请输入设备序列号', trigger: 'blur' }],
          playDeviceSeriNo: [{ required: false, message: '请输入播放序列号（默认设备序列号）', trigger: 'blur' }],
          playChannel: [{ required: true, message: '请输入设备播放路数（默认为1）', trigger: 'blur' }],
          localIp: [{ required: false, message: '局域网ip，默认为192.168.0.40', trigger: 'blur' }],
          localPort: [{ required: false, message: '局域网端口，默认为8000', trigger: 'blur' }],
          account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
          passwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          deviceName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
          gateName: [{ required: true, message: '请选择工地出口', trigger: 'blur' }],
          placeType: [{ required: true, message: '请选择场地类型', trigger: 'blur' }],
        },
        // 查询条件
        queryParams: {
          name: '',
          status: '',
          projectId: '',
          placeType: '',
          current: 1,
          size: 100
        },
        dataList: [],
        tenantList:[],
        tenantProjectList: [], //租户工地列表

        dialogVisible: false,
        dialogVisibleInfo: false,
        dialogTitle: '添加设备',
        statusFlags: [
          {
            value: '',
            label: '全部'
          },
          {
            value: 'ENABLE',
            label: '在线'
          },
          {
            value: 'DISABLE',
            label: '已下线'
          }
        ],

        deviceUsageTypes: [
          {
            value: 'CAR_HEAD',
            label: '车头'
          },
          {
            value: 'CAR_BODY',
            label: '车斗'
          }
        ],
        gateTypes: [
          {
            value: '一号出口',
            label: '一号出口'
          },
          {
            value: '二号出口',
            label: '二号出口'
          },
          {
            value: '三号出口',
            label: '三号出口'
          },
          {
            value: '四号出口',
            label: '四号出口'
          }
        ],

        recycleList:[],
        projectList:[],
      }
    },
    computed: {
      headers() {
        return {
          'lc-token': getToken()
        }
      }
    },
    created() {
      this.$nextTick(() => {
        // 手动将表格和工具栏进行关联,否则列配置将失效
        this.$refs.table.connect(this.$refs.xToolbar)
      })
      console.log("queryAllTenant")
      this.queryAllTenant()
      this.getAllProjectList()
      this.getAllRecyleProjectList()

    },
    activated() {
      this.queryList()
    },
    mounted() {
      // feat: fixed table height
      // table高度初始化，部分模块高度不固定需要等待dom渲染结束再计算
      this.$nextTick(() => {
        this._getTableHeight()
      })
    },
    methods: {
      // feat: fixed table height
      // 返回除table高度外的额外总高度
      _getExtraHeight() {
        return 185 + this.$refs.searchBar.offsetHeight
      },

      getAllRecyleProjectList(){
        getAllRecyleProjectList().then(res=>{

          if(res.code === 200){
            let list = res.data;
            this.recycleList = []
            for(var i in list){
              let item = list[i]
              let option = {label:item.name,value:item.id}
              this.recycleList.push(option)
            }
          }
        })
      },

      getAllProjectList(){
        listAllProjectList().then(res=>{

          if(res.code === 200){
            let list = res.data;
            this.projectList = []
            for(var i in list){
              let item = list[i]
              let option = {label:item.name,value:item.id}
              this.projectList.push(option)
            }
          }
        })
      },
      // 新增
      addRow() {
        this.resetForm()
        this.dialogTitle = '添加设备'
        this.dialogVisible = true
      },
      // 编辑
      editEvent(row) {
        this.resetForm()
        this.deptsChecked=[]
        console.info(this.formData, row)
        let form = Object.assign(this.formData, row)
        this.formData = form
        this.dialogTitle = '修改设备信息'
        this.dialogVisible = true
        console.log(this.tenantList)
      },
      // 停用
      disableEvent(row) {
        let params = {
          id: row.id,
          status: 'DISABLE'
        }
        updateStatus(params).then(res => {
          if (res.code === 200) {
            this.$message.success('设备上线成功')
            this.queryList()
          } else {
            this.$message.success(res.msg)
          }
        })
      },
      // 启用
      enableEvent(row) {
        let params = {
          id: row.id,
          status: 'ENABLE'
        }
        updateStatus(params).then(res => {
          if (res.code === 200) {
            this.$message.success('设备下线成功')
            this.queryList()
          } else {
            this.$message.success(res.msg)
          }
        })
      },

      clearBindEvent(row){
        let params = {
          id: row.id
        }
        clearBind(params).then(res => {
          if (res.code === 200) {
            this.$message.success('解绑成功')
            this.queryList()
          } else {
            this.$message.success(res.msg)
          }
        })

      },
      // 行选中
      selectRows() {},
      // 查询
      queryList(flag) {
        this.listLoading = true
        queryList(this.queryParams).then(res => {
          if (res.code === 200) {
            this.dataList = res.data.records
            this.total = res.data.total
          }
          this.listLoading = false
        })
      },
      // 获取所有的工地列表
      queryAllTenant() {
        console.log('query project info')
        queryAllTenant().then(res => {
          if (res.code === 200) {
            res.data.forEach(item => {
              item.children = null
              item.label = item.name
              item.id = item.phone
            });
            // console.info("queryAllTenant: ", res.data)
            this.tenantList = res.data
          } else {
            this.$message.success(res.msg)
          }
        })
      },


      // 树形下拉值改变
      inputChange(value, instanceId) {
        this.formData.projectId = value.join(',')
      },
      treeselectChange({action, parentNode, searchQuery, callback}){
        if (action==="LOAD_CHILDREN_OPTIONS"){
          // 获取子节点信息
          let params = {
            phone: parentNode.phone
          }
          listByTenant(params).then(res => {
            if (res.code === 200) {
              if(res.data.length===0){
                parentNode['children'] = 0
              }else{
                res.data.forEach(item => {
                  item.children = null
                  item.label = item.name
                });
                console.log(res.data)
                parentNode['children'] = res.data
              }
            } else {
              this.$message.error("获取子节点失败，"+res.msg)
            }
          })
          callback()
        }else{

        }
      },
      treeNodeSelected(item){
        console.info('selected', item)
        this.formData.projectId = item.id
        this.formData.projectName = item.label
      },
      selectRecycle(item){
        console.info('selected', item)
        this.formData.projectId = item
        // this.formData.projectName = item.label
      },

      selectQueryRecycle(item){
        console.info('selected', item)
        this.queryParams.projectId = item
        // this.formData.projectName = item.label
      },

      selectDeviceUsage(item){
        this.formData.deviceUsage = item.value
      },
      selectPlaceType(item){
        this.formData.placeType = item.value
        this.formData.projectId = null
        this.formData.projectName = null;
      },
      selectQueryPlaceType(item){
        this.queryParams.placeType = item.value
        this.queryParams.projectId = null
        // this.formData.projectName = null;
      },
      selectGateName(item){
        this.formData.gateName = item.value
      },
      // 提交数据
      submitData() {
        this.$refs.formData.validate(valid => {
          // debugger;
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: '正在保存...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.6)',
              target: document.getElementById('el-dialog')
            })
            if (this.formData.id) {
              deviceEdit(this.formData).then(res => {
                if (res.code === 200) {
                  loading.close()
                  this.$message.success('修改设备信息成功')
                  this.resetForm()
                  this.queryList()
                  this.dialogVisible = false
                } else {
                  loading.close()
                  this.$message.error(res.msg)
                }
              })
            } else {
              deviceSave(this.formData).then(res => {
                if (res.code === 200) {
                  this.$message.success('新增设备成功')
                  this.resetForm()
                  this.queryList()
                  loading.close()
                  this.dialogVisible = false
                } else {
                  this.$message.error(res.msg)
                  loading.close()
                }
              })
            }
          } else {
            return
          }
        })
      },

      // normalizer(node) {
      //   return {
      //     id: 1,
      //     label: 'children',
      //     children: null
      //   }
      // },
      // 重置表单
      resetForm() {
        this.formData = {
          id: '',
          seriNo: '',
          deviceName: '',
          account: '',
          passwd: '',
          projectId: '',
          projectName: null,
          playDeviceSeriNo: '',
          localIp: '',
          localPort: null,
          deviceUsage:null,
          placeType:'PROJECT',
          macAddress:'',
          playChannel: '',
          gateName: '',
        }
      }
    }
  }
  </script>

  <style rel="stylesheet/scss" lang="scss" scoped>
  .input-cls {
    width: 260px;
    font-size: 14px;
    // feat: 搜索栏过多收起
    margin-right: 10px;
  }
  .icon-cls-green {
    color: #67c23a;
  }
  .dialog-row {
    margin-top: 10px;
  }
  </style>

  <style rel="stylesheet/scss" lang="scss">
  .validFieldClass .el-input__inner {
    border-color: #f56c6c;
  }
  .el-pagination {
    float: right;
  }
  .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
  .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
    background-color: #dfecfb;
  }
  .vxe-table--footer .col-blue {
    background: #f2f5f0;
  }
  /*单号复制样式 开始*/
  .copy-content {
    right: 0.1em;
    top: 0.1em;
    float: right !important;
    position: absolute !important;
  }
  .copy-content:hover {
    background-color: #1482f0;
    float: right !important;
    height: 1.8em;
    width: 1.8em;
  }
  .write-color {
    border-color: #f0f0f0;
  }
  /*单号复制样式 结束*/
  .input-width-6 {
    width: 60% !important;
  }
  .input-width-7 {
    width: 70% !important;
  }
  .input-width-8 {
    width: 80% !important;
  }
  </style>
